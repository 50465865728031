import React from 'react'

import { renderOnDesktop } from 'components/Media/renderOnDesktop'

import { DarkGlass } from './DarkGlass'
import { Logo } from './Logo'
import { MainMenu } from './MainMenu'
import { Menu } from './Menu'
import { User } from './User'
import { AppBarBox, EmptySpace, FixedFix, StyledContainer } from './styles'

function DesktopAppBarComponent() {
  return (
    <>
      <AppBarBox>
        <StyledContainer>
          <Menu />
          <Logo />
          <MainMenu />
          <EmptySpace />
          <User />
        </StyledContainer>
        <DarkGlass />
      </AppBarBox>
      <FixedFix />
    </>
  )
}

export const DesktopAppBar = renderOnDesktop(DesktopAppBarComponent)
