import React from 'react'

import type { MenuItem } from 'types/MenuItem'

import { IconLink } from './IconLink'

interface Props {
  item: MenuItem
}

export function MenuItem({ item }: Props) {
  return (
    <IconLink
      isEnabled={true}
      link={item.uri}
      label={item.label}
      icon={item.icon}
    />
  )
}
