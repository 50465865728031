import { boolean, object } from 'yup'
import { z } from 'zod'

export const isCancelAvailableSchema = object().shape({
  cancelWithdrawalAvailable: boolean().required().default(false),
})

export const depositPromoCodeValidationResponseSchema = z.string()

export const depositPromoCodeValidationRequestSchema = z.object({
  amount: z.number(),
  depositPromoCode: z.string(),
  paymentType: z.string(),
})
