import React from 'react'

import PropTypes, { InferProps } from 'prop-types'

import { money as toMoney } from '../../utils/numbers'

type Props = InferProps<typeof Money.propTypes>

function Money({ credits, money, ...rest }: Props) {
  const value = credits ? toMoney(credits, true) : toMoney(money, false)

  return <span {...rest}>{value}</span>
}

Money.propTypes = {
  credits: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  money: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Money
