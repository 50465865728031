import Container from 'components/Container'
import styled from 'styled-components'
import { grayDark } from 'utils/style-utils'

const headerHeight = '4rem'

export const AppBarBox = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  border-bottom: 1px solid ${grayDark};
`

export const StyledContainer = styled(Container)`
  display: flex;
  height: ${headerHeight};
  justify-content: center;
  align-items: center;
`

export const EmptySpace = styled.div`
  flex: 1;
`
export const FixedFix = styled.div`
  height: ${headerHeight};
`
