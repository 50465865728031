import { darken, lighten } from '../utils/style-fns'
import baseTheme from './baseTheme'
import logoSymbolSvg from './img/b_round_red.svg'
import bannerImg from './img/banner_pixi.jpg'
import logo from './img/betor_red_no_claim.svg'

export const logoSymbol = logoSymbolSvg

const logoHeight = '65%'
const footerLogo = logo
const bannerBigImg = bannerImg

const white = '#ffffff'
const grayLightest = '#E3E3E3'
const grayLighter = '#aaaaaa'
const grayLight = '#888888'
const gray = '#666666'
const grayDark = '#303439'
const grayDarker = '#1D2026'
const grayDarkest = '#0F1419'
const black = '#000'

const brandPrimary = '#ED1941'
const brandPrimaryDark = darken(brandPrimary, 0.2)
const brandPrimaryDarker = darken(brandPrimary, 0.4)
const brandSecondary = '#5AC9E4'
const brandSecondaryDark = darken(brandSecondary, 0.3)
const brandSecondaryDarker = '#10657a'
const hrColor = 'rgba(255, 255, 255, 0.1)'

const dangerDark = '#FF8900'
const dangerDarkest = darken(dangerDark, 0.6)

const warningBg = grayDark
const dangerBg = dangerDark
const infoBg = grayLightest
const successBg = grayLightest

const warningColor = dangerDark
const dangerColor = black
const infoColor = '#243644'
const successColor = black

const color = white
const bg = '#000102'
const bgAccent = '#0c2633'

/** HEADER */
const mainHeaderBg = black
const mainHeaderColor = grayLightest
const secondaryHeaderBg = grayDarkest
const secondaryHeaderColor = grayLighter

/** CMS */
const cmsContentBg = grayDarkest
const cmsContentColor = white
const cmsContentSidebarBg = grayDark
const cmsContentSidebarColor = grayLightest
const cmsContentSidebarActiveBg = grayDarkest
const cmsContentSidebarActiveColor = brandSecondary
const cmsContentSidebar3DeepBg = grayDarker
const cmsContentSidebar3DeepColor = grayLightest

/** BTN */
const btnDefaultBg = lighten(grayLighter, 0.3)
const btnDefaultColor = grayDarkest
const btnDefaultHoverBg = grayLightest
const btnDefaultHoverColor = black

const btnPrimaryBg = darken(brandPrimary, 0.15)
const btnPrimaryColor = white
const btnPrimaryHoverBg = brandPrimary
const btnPrimaryHoverColor = white

const btnSecondaryBg = brandSecondary
const btnSecondaryColor = grayDarkest
const btnSecondaryHoverBg = lighten(brandSecondary, 0.4)
const btnSecondaryHoverColor = black

const btnDangerBg = dangerDark
const btnDangerColor = dangerColor
const btnDangerHoverBg = lighten(dangerDark, 0.4)
const btnDangerHoverColor = dangerColor

const btnDisabledBg = gray
const btnDisabledColor = grayLighter

/** FORM */
const formFieldBg = darken(grayDark, 0.2)
const formFieldColor = white
const formFieldBorderColor = lighten(formFieldBg, 0.2)
const formFieldPlaceholderColor = grayLight

const formFieldDisableBg = darken(grayDark, 0.5)
const formFieldDisableColor = lighten(grayDark, 0.3)
const formFieldDisableBorderColor = lighten(formFieldDisableBg, 0.1)

const formFieldOutline = brandSecondary
const formFieldError = dangerDark
const formFieldErrorColor = lighten(dangerBg, 0.4)

const formCheckboxBg = white
const formCheckboxColor = formFieldColor
const formCheckboxCheckedBg = white
const formCheckboxCheckedColor = formFieldColor

/** BOX */
const boxHeaderBg = grayDark
const boxHeaderColor = white
const boxSubHeaderBg = grayDark
const boxSubHeaderColor = grayLighter
const boxContentBg = grayDarker
const boxContentColor = white
const boxFooterBg = grayDarker
const boxFooterColor = grayLightest

/** Table */
const tableHeaderBg = black
const tableHeaderColor = grayLightest
const tableBg = boxContentBg
const tableColor = boxContentColor
const tableDangerBg = dangerDarkest

/** SELECT BOX */
const selectBoxBg = boxContentBg
const selectBoxColor = boxContentColor
const selectBoxIconColor = boxContentColor
const selectBoxActiveBg = boxHeaderBg
const selectBoxActiveColor = brandSecondary
const selectBoxActiveIconColor = brandSecondary
const selectBoxSelectedBg = boxHeaderBg
const selectBoxSelectedColor = boxHeaderColor
const selectBoxSelectedIconColor = boxHeaderColor
const selectBoxSelectedBorderColor = boxHeaderColor
const selectBoxWarningBg = grayDark
const selectBoxWarningColor = dangerDark
const selectBoxWarningIconColor = dangerDark

const alertBoxShadow = `rgba(0,0,0,0.8) 0 ${baseTheme.bh / 4}px ${baseTheme.bh}px`
const loaderColor = grayLight

export default {
  ...baseTheme,

  logo,
  logoHeight,
  footerLogo,

  alertBoxShadow,
  tableHeaderBg,
  tableHeaderColor,
  tableBg,
  tableColor,
  tableDangerBg,

  bg,
  bgAccent,
  color,
  bannerImg,
  bannerBigImg,

  brandPrimary,
  brandPrimaryDark,
  brandPrimaryDarker,
  brandSecondary,
  brandSecondaryDark,
  brandSecondaryDarker,
  white,
  grayLightest,
  grayLighter,
  grayLight,
  gray,
  grayDark,
  grayDarker,
  grayDarkest,
  black,
  warningBg,
  dangerBg,
  infoBg,
  successBg,
  warningColor,
  dangerColor,
  infoColor,
  successColor,
  dangerDark,
  dangerDarkest,
  hrColor,

  mainHeaderBg,
  mainHeaderColor,
  secondaryHeaderBg,
  secondaryHeaderColor,

  cmsContentBg,
  cmsContentColor,
  cmsContentSidebarBg,
  cmsContentSidebarColor,
  cmsContentSidebarActiveBg,
  cmsContentSidebarActiveColor,
  cmsContentSidebar3DeepBg,
  cmsContentSidebar3DeepColor,

  boxHeaderBg,
  boxHeaderColor,
  boxSubHeaderBg,
  boxSubHeaderColor,
  boxContentBg,
  boxContentColor,
  boxFooterBg,
  boxFooterColor,

  formFieldBg,
  formFieldColor,
  formFieldBorderColor,
  formFieldPlaceholderColor,
  formFieldDisableBg,
  formFieldDisableColor,
  formFieldDisableBorderColor,
  formFieldOutline,
  formFieldError,
  formFieldErrorColor,
  formCheckboxBg,
  formCheckboxColor,
  formCheckboxCheckedBg,
  formCheckboxCheckedColor,

  btnDefaultBg,
  btnDefaultColor,
  btnDefaultHoverBg,
  btnDefaultHoverColor,
  btnPrimaryBg,
  btnPrimaryColor,
  btnPrimaryHoverBg,
  btnPrimaryHoverColor,
  btnSecondaryBg,
  btnSecondaryColor,
  btnSecondaryHoverBg,
  btnSecondaryHoverColor,
  btnDangerBg,
  btnDangerColor,
  btnDangerHoverBg,
  btnDangerHoverColor,
  btnDisabledBg,
  btnDisabledColor,

  selectBoxBg,
  selectBoxColor,
  selectBoxIconColor,
  selectBoxActiveBg,
  selectBoxActiveColor,
  selectBoxActiveIconColor,
  selectBoxSelectedBg,
  selectBoxSelectedColor,
  selectBoxSelectedIconColor,
  selectBoxSelectedBorderColor,
  selectBoxWarningBg,
  selectBoxWarningColor,
  selectBoxWarningIconColor,

  loaderColor,
}
