import styled from 'styled-components'

export const DarkGlass = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  backdrop-filter: blur(0.5rem) brightness(0.5) saturate(1.5);
`
