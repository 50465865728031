import styled from 'styled-components'

import { media, bh } from '../../utils/style-utils'

function getPercent(col: number | string, spaced: boolean) {
  if (col === 12) {
    return '100%'
  }

  if (typeof col === 'string') {
    return col
  }
  const fraction = Math.floor((col / 12) * 1000) / 1000 - (spaced ? 0 : 0.01)

  return `${fraction * 100}%`
}

function display(actualVal: number, colVal: number) {
  if (actualVal === 0) {
    return 'display: none'
  }

  if (actualVal && colVal === 0) {
    return 'display: block'
  }

  return ''
}

interface Props {
  col?: number
  max?: boolean
  center?: boolean
  right?: boolean
  auto?: boolean
  spaced?: boolean
  xl?: number
  lg?: number
  md?: number
  sm?: number
  xs?: number
}

export default styled.div<Props>`
  width: ${(props) => getPercent(props.col || (props.auto || props.max ? 'auto' : 12), props.spaced)};
  ${(props) => (props.col === 0 ? 'display: none' : '')};
  ${(props) => (props.max ? 'flex-grow: 1' : '')};
  ${(props) => (props.center ? 'text-align: center' : '')};
  ${(props) => (props.right ? 'text-align: right' : '')};
  ${(props) =>
    props.spaced
      ? `
    padding-right: ${props.col === 13 ? 0 : bh * 2}px;
  `
      : ''};

  &:last-child {
    padding-right: 0;
  }

  /* ${(props) => (props.spaced && props.col === 12 ? 'padding-right: 0;' : '')}; */

  ${media.xl`
    ${(props: Props) => (props.xl ? `width: ${getPercent(props.xl, props.spaced)}` : '')};
    ${(props: Props) => display(props.xl, props.col)};
    ${(props: Props) => (props.spaced && props.xl === 12 ? 'padding-right: 0;' : '')};
  `}

  ${media.lg`
    ${(props: Props) => (props.lg ? `width: ${getPercent(props.lg, props.spaced)}` : '')};
    ${(props: Props) => display(props.lg, props.col)};
    ${(props: Props) => (props.spaced && props.lg === 12 ? 'padding-right: 0;' : '')};
  `}

  ${media.md`
    ${(props: Props) => (props.md ? `width: ${getPercent(props.md, props.spaced)}` : '')};
    ${(props: Props) => display(props.md, props.col)};
    ${(props: Props) => (props.spaced && props.md === 12 ? 'padding-right: 0;' : '')};
  `}

  ${media.sm`
    ${(props: Props) => (props.sm ? `width: ${getPercent(props.sm, props.spaced)}` : '')};
    ${(props: Props) => display(props.sm, props.col)};
    ${(props: Props) => (props.spaced && props.sm === 12 ? 'padding-right: 0;' : '')};
  `}

  ${media.xs`
    ${(props: Props) => (props.xs ? `width: ${getPercent(props.xs, props.spaced)}` : '')};
    ${(props: Props) => display(props.xs, props.col)};
    ${(props: Props) => (props.spaced && props.xs === 12 ? 'padding-right: 0;' : '')};
  `}
`
