import React, { ComponentType, FunctionComponent, ReactElement } from 'react'

import { isNil } from 'lodash'

interface WithConditionProps {
  condition?: boolean
}

export default function withCondition<T>(Component: ComponentType<T>): FunctionComponent<T & WithConditionProps> {
  return function WithCondition({ condition, ...props }: any): ReactElement<T> {
    if (isNil(condition) || condition) {
      return <Component {...props} />
    }

    return null
  }
}
