import { useSelector } from 'react-redux'

import { Query, QueryValue, RouterStateWithQuery } from '../../routing/routeReducerWithQuery'

export function useURLSearchParams<TReturnType = QueryValue>(selector: (query: Query) => TReturnType): TReturnType
export function useURLSearchParams(): Query

export function useURLSearchParams<TReturnType = QueryValue>(selector?: (query: Query) => TReturnType) {
  const query = useSelector((state: RouterStateWithQuery) => {
    const queryState = state?.get('router').location?.query

    if (selector) {
      return selector(queryState)
    }

    return queryState
  })

  return query
}
