import React from 'react'

import UserIcon from 'components/Icons/components/User'
import Money from 'components/Money'
import { PLAYER_PROFILE_ADDRESS } from 'containers/App/constants'
import { useUserAccountSum } from 'containers/MainLayout/selectors'

import { ImportantButtons } from './ImportantButtons'
import { ProfileLink } from './styles'

export function Player() {
  const accountSum = useUserAccountSum()

  return (
    <>
      <ImportantButtons />

      <ProfileLink
        id="login-user-button-mobile"
        to={PLAYER_PROFILE_ADDRESS}
      >
        <UserIcon />
        <Money money={accountSum} />
      </ProfileLink>
    </>
  )
}
