/* eslint-disable no-multi-spaces */
import { isKioskCached } from '../utils/device'
import { lighten, darken, hexRgba } from '../utils/style-fns'
import bannerImg from './img/banner.jpg'
import logo from './img/betor_bily_no_claim.svg'
import bannerBigImg from './img/big-banner.jpg'

const bh = isKioskCached() ? 18 : 16 // base height

const logoHeight = '50%'
const footerLogo = logo

const extraFontFamily = 'Titillium Web'
const baseFontFamily = 'Titillium Web, Verdana, Geneva, sans-serif'
const fontWeightLight = 400
const fontWeightMedium = 600
const fontWeightBold = 700

const bg = '#070707'
const bgAccent = '#070707'
const brandPrimary = '#2ec8a0'
const brandPrimaryDark = '#007253'
const brandPrimaryDarker = '#004734'
const brandSecondary = '#f9ab1a'
const brandSecondaryDark = '#926104'
const brandSecondaryDarker = '#624103'

const warningBg = '#fcefbd'
const dangerBg = '#f44c3f'
const infoBg = '#bbe1ff'
const successBg = '#b9f2cb'

const warningColor = '#a06f1b'
const dangerColor = '#fff'
const infoColor = '#243644'
const successColor = '#043f30'

const dangerDark = '#b71d0f'
const dangerDarkest = '#2f0400'

const white = '#ffffff'
const grayLightest = '#dddddd'
const grayLighter = '#aaaaaa'
const grayLight = '#888888'
const gray = '#666666'
const grayDark = '#343434'
const grayDarker = '#222222'
const grayDarkest = '#111111'
const black = '#000000'

const color = white

/** LIGHT */
const brightLight = '#d0ddda'
const brightLighter = '#e1eae8'
const brightColor = black

const red = '#F30F37'

/** FORM */
const formFieldHeight = bh * 3
const formFieldBg = darken(grayDark, 0.2)
const formFieldColor = white
const formFieldLabelColor = grayLightest
const formFieldBorderColor = lighten(formFieldBg, 0.2)
const formFieldPlaceholderColor = grayLight

const formFieldDisableBg = darken(grayDark, 0.5)
const formFieldDisableColor = lighten(grayDark, 0.3)
const formFieldDisableBorderColor = lighten(formFieldDisableBg, 0.1)

const formFieldOutline = brandPrimary
const formFieldError = dangerDark
const formFieldErrorColor = lighten(dangerBg, 0.4)

const formCheckboxBg = white
const formCheckboxColor = formFieldColor
const formCheckboxCheckedBg = successBg
const formCheckboxCheckedColor = white
const formFieldAutocompleteColor = white

/** HEADER */
const mainHeaderBg = brandPrimaryDark
const mainHeaderColor = white
const secondaryHeaderBg = brandPrimaryDarker
const secondaryHeaderColor = white

/** CONTENT */
const cmsContentBg = grayDarkest
const cmsContentColor = grayLightest
const cmsContentSidebarBg = grayDark
const cmsContentSidebarColor = grayLightest
const cmsContentSidebarActiveBg = grayDarkest
const cmsContentSidebarActiveColor = brandPrimary
const cmsContentSidebar3DeepBg = grayDarker
const cmsContentSidebar3DeepColor = grayLighter
const cmsContentBorder = grayDarkest
const hrColor = 'rgba(255, 255, 255, 0.1)'

/** BOX */
const boxHeaderBg = brandPrimaryDark
const boxHeaderColor = lighten(brandPrimary, 0.5)
const boxSubHeaderBg = brandPrimaryDarker
const boxSubHeaderColor = grayLightest
const boxContentBg = grayDarker
const boxContentColor = grayLightest
const boxFooterBg = grayDarkest
const boxFooterColor = grayLightest
const attachmentColor = lighten(gray, 0.75)

/** TABLE */
const tableHeaderBg = black
const tableHeaderColor = grayLightest
const tableBg = boxContentBg
const tableColor = boxContentColor
const tableDangerBg = dangerDarkest

/** BTN */
const btnDefaultBg = grayLighter
const btnDefaultColor = grayDarkest
const btnDefaultHoverBg = grayLightest
const btnDefaultHoverColor = black

const btnPrimaryBg = brandPrimary
const btnPrimaryColor = darken(brandPrimaryDarker, 0.5)
const btnPrimaryHoverBg = lighten(brandPrimary, 0.5)
const btnPrimaryHoverColor = black

const btnSecondaryBg = brandSecondary
const btnSecondaryColor = darken(brandSecondaryDark, 0.85)
const btnSecondaryHoverBg = lighten(brandSecondary, 0.5)
const btnSecondaryHoverColor = black

const btnDangerBg = darken(dangerBg, 0.2)
const btnDangerColor = white
const btnDangerHoverBg = darken(dangerBg, 0.5)
const btnDangerHoverColor = white

const btnDisabledBg = gray
const btnDisabledColor = grayLighter

const btnPadding = `${bh / 2 - 1}px ${bh * 1.5}px ${bh / 2}px;`

/** SELECT BOX */
const selectBoxBg = boxContentBg
const selectBoxColor = boxContentColor
const selectBoxIconColor = boxContentColor
const selectBoxActiveBg = boxHeaderBg
const selectBoxActiveColor = boxHeaderColor
const selectBoxActiveIconColor = boxHeaderColor
const selectBoxSelectedBg = boxHeaderBg
const selectBoxSelectedColor = boxHeaderColor
const selectBoxSelectedIconColor = boxHeaderColor
const selectBoxSelectedBorderColor = boxHeaderColor
const selectBoxWarningBg = warningBg
const selectBoxWarningColor = boxHeaderColor
const selectBoxWarningIconColor = boxHeaderColor

/** ALERT */
const alertBoxShadow = `${hexRgba(black, 0.5)} 0 ${bh}px ${bh * 2}px`

/** LOADER */
const loaderColor = brandPrimary

export default {
  tableHeaderBg,
  tableHeaderColor,
  tableBg,
  tableColor,
  tableDangerBg,
  alertBoxShadow,

  baseFontFamily,
  extraFontFamily,
  fontWeightLight,
  fontWeightMedium,
  fontWeightBold,

  logo,
  logoHeight,
  footerLogo,
  bannerImg,
  bannerBigImg,
  bg,
  bgAccent,
  color,
  hrColor,

  mainHeaderBg,
  mainHeaderColor,
  secondaryHeaderBg,
  secondaryHeaderColor,
  cmsContentBg,
  cmsContentColor,
  cmsContentSidebarBg,
  cmsContentSidebarColor,
  cmsContentSidebarActiveBg,
  cmsContentSidebarActiveColor,
  cmsContentSidebar3DeepBg,
  cmsContentSidebar3DeepColor,
  cmsContentBorder,
  boxHeaderBg,
  boxHeaderColor,
  boxSubHeaderBg,
  boxSubHeaderColor,
  boxContentBg,
  boxContentColor,
  boxFooterBg,
  boxFooterColor,
  attachmentColor,

  bh,

  brandPrimary,
  brandPrimaryDark,
  brandPrimaryDarker,
  brandSecondary,
  brandSecondaryDark,
  brandSecondaryDarker,
  warningBg,
  dangerBg,
  infoBg,
  successBg,
  warningColor,
  dangerColor,
  infoColor,
  successColor,
  dangerDark,
  dangerDarkest,
  white,
  grayLightest,
  grayLighter,
  grayLight,
  gray,
  grayDark,
  grayDarker,
  grayDarkest,
  black,
  brightLight,
  brightLighter,
  brightColor,
  red,
  formFieldHeight,
  formFieldBg,
  formFieldColor,
  formFieldLabelColor,
  formFieldBorderColor,
  formFieldPlaceholderColor,
  formFieldDisableBg,
  formFieldDisableColor,
  formFieldDisableBorderColor,
  formFieldOutline,
  formFieldError,
  formFieldErrorColor,
  formCheckboxBg,
  formCheckboxColor,
  formCheckboxCheckedBg,
  formCheckboxCheckedColor,
  formFieldAutocompleteColor,

  btnDefaultBg,
  btnDefaultColor,
  btnDefaultHoverBg,
  btnDefaultHoverColor,
  btnPrimaryBg,
  btnPrimaryColor,
  btnPrimaryHoverBg,
  btnPrimaryHoverColor,
  btnSecondaryBg,
  btnSecondaryColor,
  btnSecondaryHoverBg,
  btnSecondaryHoverColor,
  btnDangerBg,
  btnDangerColor,
  btnDangerHoverBg,
  btnDangerHoverColor,
  btnDisabledBg,
  btnDisabledColor,
  btnPadding,

  loaderColor,

  selectBoxBg,
  selectBoxColor,
  selectBoxIconColor,
  selectBoxActiveBg,
  selectBoxActiveColor,
  selectBoxActiveIconColor,
  selectBoxSelectedBg,
  selectBoxSelectedColor,
  selectBoxSelectedIconColor,
  selectBoxSelectedBorderColor,
  selectBoxWarningBg,
  selectBoxWarningColor,
  selectBoxWarningIconColor,
}
