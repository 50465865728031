import { Suggestion } from '../../components/Form/Autocomplete/types'
import {
  BIRTH_PLACE_FIELD,
  CITY_FIELD,
  PLACE_FIELD,
  STREET_FIELD,
} from '../../components/Register/StandardRegistration/BetorCZ/Fields/utils'
import { useGenericChangeFormValue } from '../FieldBox/useChangeFormValue'
import { FORM_REGISTER_NAME } from './constants'

function transformSuggestionToId(suggestion: Suggestion<any>): number {
  if (!suggestion) {
    return null
  }

  return suggestion.id
}

export function transformSuggestionsFields(payload: any): any {
  return {
    ...payload,
    registration_external: {
      ...payload.registration_external,
      [CITY_FIELD]: transformSuggestionToId(payload.registration_external[CITY_FIELD]),
      [STREET_FIELD]: transformSuggestionToId(payload.registration_external[STREET_FIELD]),
      [PLACE_FIELD]: transformSuggestionToId(payload.registration_external[PLACE_FIELD]),
      [BIRTH_PLACE_FIELD]: transformSuggestionToId(payload.registration_external[BIRTH_PLACE_FIELD]),
    },
  }
}

export function useChangeFormValue(field: string) {
  return useGenericChangeFormValue(FORM_REGISTER_NAME, field)
}
