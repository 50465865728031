import { mapValues } from 'lodash'
import { string, object, array, number, lazy } from 'yup'

export const game = object({
  backgroundPath: string(),
  badges: array(string()).required(),
  category: array(string()).required(),
  description: string(),
  forFunPlayUrl: string().required(),
  imagePath: string().required(),
  isHtml5Enabled: string().required(),
  name: string().required(),
  number: string().required(),
  languages: array(string()).required(),
  producer: string().required(),
  provider: string().required(),
  realPlayUrl: string().required(),
  searchIndex: string().required(),
  slug: string().required(),
  topRankPosition: number(),
})

export const gamesSchema = lazy((key) => object(mapValues(key, () => game)))
