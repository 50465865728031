/* eslint-disable @typescript-eslint/await-thenable */
import { request } from '../../utils/request'
import { ADD_GAME_TO_FAVORITES, REMOVE_GAME_FROM_FAVORITES } from '../App/constants'
import { GAMES_LOAD_URI } from '../Games/constants'

export async function getGames(): Promise<unknown> {
  const { data } = await request(GAMES_LOAD_URI)

  return data
}

export async function addToFavorites(slug: string): Promise<unknown> {
  const requestURL = `${ADD_GAME_TO_FAVORITES}/${slug}`

  return request(requestURL)
}

export async function removeFromFavorites(slug: string): Promise<unknown> {
  const requestURL = `${REMOVE_GAME_FROM_FAVORITES}/${slug}`

  return request(requestURL)
}
