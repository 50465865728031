import React from 'react'

import { Show } from 'components/Media/Show'
import { useSelectMainMenu } from 'containers/MainLayout/selectors'

import { DarkGlass } from '../DarkGlass'
import { MenuItem } from './MenuItem'
import { MainMenuBox } from './styles'

export function MainMenu() {
  const menu = useSelectMainMenu()

  return (
    <MainMenuBox>
      {menu.map((item, id) => (
        <MenuItem
          key={id}
          item={item}
        />
      ))}

      <Show on="sm">
        <DarkGlass />
      </Show>
    </MainMenuBox>
  )
}
