import React from 'react'

import { Autocomplete } from '../../../components/Form/Autocomplete'
import { Suggestion } from '../../../components/Form/Autocomplete/types'
import { FormProps } from '../../../components/Form/types'
import { StreetDTO, StreetSuggestionsQueryParams } from './types'
import { getStreetSuggestions } from './useStreetSuggestions'

interface Props extends FormProps {
  isDisabled?: boolean
  queryParams: StreetSuggestionsQueryParams
  onCustomChange?: (params?: Suggestion<StreetDTO>) => void
}

function StreetAutocomplete({ onChange, onCustomChange, isDisabled, disable, ...props }: Props) {
  return (
    <Autocomplete
      fetchData={getStreetSuggestions}
      disable={disable || isDisabled}
      onChange={(value) => {
        onChange(value)
        onCustomChange(value)
      }}
      {...props}
    />
  )
}

export default StreetAutocomplete
