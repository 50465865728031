import React from 'react'

import { HOME_ADDRESS, THEME } from 'containers/App/constants'
import { logo } from 'utils/style-utils'

import { Img, LogoBox, LogoLink } from './styles'

function strollToTop() {
  window.scroll({
    top: 0,
    behavior: 'smooth',
  })
}

export function Logo() {
  return (
    <LogoBox>
      <LogoLink
        to={`${HOME_ADDRESS}`}
        onClick={strollToTop}
      >
        <Img
          src={logo}
          alt={THEME}
        />
      </LogoLink>
    </LogoBox>
  )
}
