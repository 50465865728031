import { array, object, number, boolean } from 'yup'

import { tournamentDateSchema } from '../schemas/tournamentDateSchema'

export const userMembershipSchema = object({
  membershipId: number().required(),
  tournamentId: number().required(),
  rewardAmt: number().nullable(),
  classified: boolean().required(),
  score: number().nullable(),
  position: number().nullable(),
  subscribedAt: tournamentDateSchema,
})

export const userMembershipsListSchema = array().of(userMembershipSchema)
