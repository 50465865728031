import React from 'react'
import { Switch } from 'react-router'
import { Route } from 'react-router-dom'

import PanicButtonActionLoadable from 'containers/PanicButtonActionPage/Loadable'

import { DEBUG } from '../containers/App/constants'
import LoginCardPage from '../containers/LoginCardPage/Loadable'
import Login from '../containers/LoginPage/LoadableLoginPage'
import RegisterPage from '../containers/RegisterPage/Loadable'
import IconsLoadable from '../dev/IconsPreview/Loadable'
import MainLayoutSwitch from './MainLayoutSwitch'

export default function MainRoute() {
  return (
    <Switch>
      <Route
        path="/:lang/login/card/:cardId"
        component={LoginCardPage}
      />
      <Route
        exact
        path="/:lang/register"
        component={RegisterPage}
      />
      <Route
        path="/:lang/login"
        component={Login}
      />
      <Route
        path="/:lang/profile/panic-button-action"
        component={PanicButtonActionLoadable}
      />
      {DEBUG && (
        <Route
          path="/dev/icons"
          component={IconsLoadable}
        />
      )}
      <Route>
        <MainLayoutSwitch />
      </Route>
    </Switch>
  )
}
