import { FlashMessageTypes } from '../../components/FlashMessages/types'
import { flashMessagesResponseSchema } from '../FlashMessagesBox/schema'
import { DEFAULT_QUERY_BUSTER_KEY } from './constants'

export function getQueryBusterKey() {
  return window.APP?.cacheVersion || DEFAULT_QUERY_BUSTER_KEY
}

export function handleOnSuccess(showFlashMessage: (message: string, type?: FlashMessageTypes) => void) {
  return (queryData: unknown) => {
    const validatedData = flashMessagesResponseSchema.safeParse(queryData)

    if (validatedData.success) {
      const {
        data: { flashMessages },
      } = validatedData

      flashMessages.forEach(({ text, type }) => {
        showFlashMessage(text, type as FlashMessageTypes)
      })
    }
  }
}
