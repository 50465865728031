import UrlLink from 'components/UrlLink'
import styled from 'styled-components'
import { grayDark, mainHeaderColor, media, transition, white } from 'utils/style-utils'

export const MainMenuBox = styled.div`
  position: relative;
  display: flex;
  height: 100%;

  ${media.sm`
    position: fixed;
    width: 100%;
    height: 4rem;
    left: 0;
    z-index: 10;

    border-top: 1px solid ${grayDark};

    top: calc(100dvh - 4rem);
    ${transition('top', 0.6)};
  `}
`

export const StyledUrlLink = styled(UrlLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  min-width: 6rem;
  padding: 0 2rem;

  color: ${mainHeaderColor};
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 800;
  background: rgba(0, 0, 0, 0);

  ${transition('all', 0.6)};

  > svg {
    width: 2rem;
    height: 2rem;
    fill: ${mainHeaderColor};
    display: block;
    ${transition('all', 0.6)}
  }

  &:hover {
    color: ${white};
    text-shadow:
      0 0 4px ${white},
      0 0 16px ${white},
      0 0 32px ${white},
      0 0 64px ${white};

    > svg {
      filter: drop-shadow(0 0 4px ${mainHeaderColor}) drop-shadow(0 0 16px ${mainHeaderColor});
    }
  }

  & > svg {
    display: none;
  }

  ${media.sm`
    & > svg {
      display: block;
    }

    & > span {
      display: none;
    }
  `}
`
