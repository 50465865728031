export const deleteCookie = (cookieName: string): void => {
  document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

export const setCookie = (cookieName: string, value: string): void => {
  const now = new Date()
  const time = now.getTime()
  const expireTime = time + 10 * 365 * 24 * 60 * 60 * 1000 // now + 10 years
  now.setTime(expireTime)
  document.cookie = `${cookieName}=${value}; path=/; expires=${now.toUTCString()};`
}

export const isCookieSet = (cookieName: string): boolean => document.cookie.includes(cookieName)

export const getCookie = (cookieName: string): string => {
  const name = `${cookieName}=`
  const decoded = decodeURIComponent(document.cookie)
  const allCookies = decoded.split('; ')
  let cookie

  allCookies.forEach((val) => {
    if (val.indexOf(name) === 0) {
      cookie = val.substring(name.length)
    }
  })

  return cookie
}
