import { ANALYTICS_CODE, ANALYTICS_TIMEOUT } from '../App/constants'
import { AnalyticsActions, AnalyticsConsent, AnalyticsConsentAction, AnalyticsEventCategory } from './constants'

const SCRIPT_SOURCE = `https://www.googletagmanager.com/gtm.js?id=${ANALYTICS_CODE}`
const IFRAME_SOURCE = `https://www.googletagmanager.com/ns.html?id=${ANALYTICS_CODE}`
const SEND_GOOGLE_CONSENTS_THRESHOLD = 10

export function googleTagEvent(
  action: AnalyticsActions,
  eventCategory: AnalyticsEventCategory,
  params?: Record<string, any>,
): Promise<void> {
  return new Promise((resolve) => {
    const { gTagAnalyticsEnabled } = window as any

    if (gTagAnalyticsEnabled) {
      gtag('event', `<${action}>`, {
        event_category: `<${eventCategory}>`,
        event_callback: createFunctionWithTimeout(() => resolve()),
        ...params,
      })
    } else {
      resolve()
    }
  })
}

function isGoogleTagManagerAlreadyInitialized() {
  return Array.from(document.getElementsByTagName('script')).some((script) => script.src === SCRIPT_SOURCE)
}

export function analyticsInit(statistical: boolean) {
  if (statistical && ANALYTICS_CODE && !isGoogleTagManagerAlreadyInitialized()) {
    const noScript = getGTMNoScriptElement()
    const script = getGTMScriptElement()

    document.head.appendChild(script)
    document.body.appendChild(noScript)

    return () => {
      document.head.removeChild(script)
      document.body.removeChild(noScript)
    }
  }

  return undefined
}

function getGTMScriptElement() {
  const script = document.createElement('script')
  script.src = SCRIPT_SOURCE
  script.async = true

  script.onload = () => {
    ;(window as any).dataLayer = (window as any).dataLayer || []
    gtag({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    })
    ;(window as any).gTagAnalyticsEnabled = true

    defaultGoogleConsents()
  }

  return script
}

function getGTMNoScriptElement() {
  const iFrame = document.createElement('iframe')
  iFrame.src = IFRAME_SOURCE
  iFrame.width = '0'
  iFrame.height = '0'
  iFrame.style.display = 'none'
  iFrame.style.visibility = 'hidden'
  const noScript = document.createElement('noscript').appendChild(iFrame)

  return noScript
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function gtag(..._args: any[]) {
  // eslint-disable-next-line prefer-rest-params
  ;(window as any).dataLayer.push(arguments)
}

function defaultGoogleConsents() {
  googleConsent(AnalyticsConsentAction.default, {
    ad_user_data: AnalyticsConsent.denied,
    ad_personalization: AnalyticsConsent.denied,
  })
}

export function updateGoogleConsents(value: boolean) {
  googleConsent(AnalyticsConsentAction.update, {
    ad_user_data: value ? AnalyticsConsent.granted : AnalyticsConsent.denied,
    ad_personalization: value ? AnalyticsConsent.granted : AnalyticsConsent.denied,
  })
}

function googleConsent(action: AnalyticsConsentAction, params?: Record<string, AnalyticsConsent>, tries = 0) {
  const { gTagAnalyticsEnabled } = window as any

  if (gTagAnalyticsEnabled) {
    gtag('consent', action, {
      ...params,
    })
  } else {
    if (tries < SEND_GOOGLE_CONSENTS_THRESHOLD) {
      setTimeout(() => googleConsent(action, params, tries++), 100)
    }
  }
}

export function createFunctionWithTimeout(callback: () => void) {
  let called = false
  function fn() {
    if (!called) {
      called = true
      callback()
    }
  }

  setTimeout(fn, ANALYTICS_TIMEOUT)

  return fn
}
