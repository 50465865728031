import { Link } from 'react-router-dom'

import UserIcon from 'components/Icons/components/User'
import styled from 'styled-components'
import { mainHeaderColor, transition, white } from 'utils/style-utils'

export const LoginLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 4rem;

  > svg {
    width: 1.6rem;
    height: 1.6rem;
    fill: ${mainHeaderColor};
    display: block;
    ${transition('filter', 0.6)}
  }

  &:hover {
    fill: ${white};
    > svg {
      filter: drop-shadow(0 0 4px ${white}) drop-shadow(0 0 16px ${white});
    }
  }
`

export const StyledUserIcon = styled(UserIcon)`
  width: 1.6rem;
  height: 1.6rem;
  fill: ${mainHeaderColor};
`
