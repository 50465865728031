import { useSelector } from 'react-redux'

import { makeSelectUser } from '../../../containers/MainLayout/selectors'
import useGamesSlugsInOngoingTournaments from '../Tournaments/useGamesSlugsInTournaments'
import { getGameCacheKey } from './utils'

export default function useGamesWithCategoriesAndUserCacheKey() {
  const user = useSelector(makeSelectUser())?.toJS()
  const gamesSlugsInTournaments = useGamesSlugsInOngoingTournaments()
  const gamesCacheKey = getGameCacheKey(
    user?.favoriteGames,
    user?.freeRounds,
    user?.unfinishedGames,
    gamesSlugsInTournaments,
  )

  return gamesCacheKey
}
