import React, { FunctionComponent } from 'react'

import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import {
  bh,
  transition,
  baseFontFamily,
  fontWeightMedium,
  btnDefaultBg,
  btnDefaultColor,
  btnDefaultHoverBg,
  btnDefaultHoverColor,
  btnPrimaryBg,
  btnPrimaryColor,
  btnPrimaryHoverBg,
  btnPrimaryHoverColor,
  btnSecondaryBg,
  btnSecondaryColor,
  btnSecondaryHoverBg,
  btnSecondaryHoverColor,
  btnDangerBg,
  btnDangerColor,
  btnDangerHoverBg,
  btnDangerHoverColor,
  btnDisabledBg,
  btnDisabledColor,
  btnPadding,
  cmsContentColor,
} from '../../utils/style-utils'
import { isSelfUrl, isRedirectNeeded } from '../../utils/url'
import BtnBg, { width as btnBgWidth, height as btnBgHeight } from '../Icons/BtnBg'
import Link from '../Link'
import SmallCenteredLoader from '../Loader/SmallCenteredLoader'

const simpleBtn = css<any>`
  display: inline-block;
  position: relative;
  border: 1px solid transparent;
  outline: none;
  margin-bottom: 1px;
  vertical-align: middle;
  box-sizing: border-box;

  text-transform: uppercase;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;

  cursor: pointer;

  padding: ${btnPadding};
  font-size: ${bh}px;
  font-family: ${baseFontFamily};
  font-weight: ${fontWeightMedium};
  color: ${btnDefaultColor};
  background-color: ${btnDefaultBg};

  ${transition('background-color', 0.5)}

  &:hover {
    color: ${btnDefaultHoverColor};
    background-color: ${btnDefaultHoverBg};
  }

  &.active {
    pointer-events: none;
    cursor: default;
  }
`

export const btn = css<any>`
  ${simpleBtn}

  & span {
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
      height: 1.5em;
      width: 1.5em;
      fill: currentcolor;

      &:first-child {
        margin-right: ${bh / 2}px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

export const btnPrimary = css<any>`
  color: ${btnPrimaryColor};
  background-color: ${btnPrimaryBg};

  &:hover {
    color: ${btnPrimaryHoverColor};
    background-color: ${btnPrimaryHoverBg};
  }
`

const btnSecondary = css`
  color: ${btnSecondaryColor};
  background-color: ${btnSecondaryBg};

  &:hover {
    color: ${btnSecondaryHoverColor};
    background-color: ${btnSecondaryHoverBg};
  }
`

const btnDanger = css`
  color: ${btnDangerColor};
  background-color: ${btnDangerBg};

  &:hover {
    color: ${btnDangerHoverColor};
    background-color: ${btnDangerHoverBg};
  }
`

export const btnDisabled = css`
  &[disabled],
  &.loading {
    cursor: not-allowed;
    pointer-events: none;
    color: ${btnDisabledColor};
    background: ${btnDisabledBg};
  }

  ${(p: any) =>
    p.disabled || p.loading
      ? `
    cursor: not-allowed;
    pointer-events: none;
    color: ${btnDisabledColor};
    background: ${btnDisabledBg};
  `
      : ''}
`

const btnBgXlMulti = 1.65

const btnSvg = css`
  ${(p: any) =>
    p.xl
      ? `
    width: ${btnBgWidth * btnBgXlMulti}px;
    height: ${btnBgHeight * btnBgXlMulti}px;
    line-height: ${btnBgHeight * btnBgXlMulti}px;
  `
      : `
    width: ${btnBgWidth}px;
    height: ${btnBgHeight}px;
    line-height: ${btnBgHeight}px;
  `};
  padding-top: 0;
  padding-bottom: 0;
  background-color: transparent !important;
  vertical-align: top;

  color: ${btnDefaultColor};

  & > .svg-bg {
    position: absolute;
    left: 0;
    top: 0;
    fill: ${btnDefaultBg};
    ${transition('fill', 0.5)}
  }

  & > span {
    position: relative;
    z-index: 1;
    font-size: ${(p: any) => (p.xl ? bh * 1.375 : bh)}px;
    top: ${(p: any) => (p.xl ? -bh / 4 : -bh / 8)}px;
  }

  &:hover {
    background-color: transparent;
    color: ${btnDefaultHoverColor};
    & > .svg-bg {
      fill: ${btnDefaultHoverBg};
    }
  }

  ${(p: any) =>
    p.primary
      ? `
    color: ${btnPrimaryColor};
    & > .svg-bg {
      fill: ${btnPrimaryBg};
    }
    &:hover {
      color: ${btnPrimaryHoverColor};
      & > .svg-bg  {
        fill: ${btnPrimaryHoverBg};
      }
    }
  `
      : ''}
  ${(p: any) =>
    p.secondary
      ? `
    color: ${btnSecondaryColor};
    & > .svg-bg {
      fill: ${btnSecondaryBg};
    }
    &:hover {
      color: ${btnSecondaryHoverColor};
      & > .svg-bg  {
        fill: ${btnSecondaryHoverBg};
      }
    }
  `
      : ''}
  ${(p: any) =>
    p.danger
      ? `
    color: ${btnDangerColor};
    & > .svg-bg {
      fill: ${btnDangerBg};
    }
    &:hover {
      color: ${btnDangerHoverColor};
      & > .svg-bg  {
        fill: ${btnDangerHoverBg};
      }
    }
  `
      : ''}
  ${(p: any) =>
    p.disabled || p.loading
      ? `
    color: ${btnDisabledColor};
    & > .svg-bg {
      fill: ${btnDisabledBg};
    }
    &:hover {
      color: ${btnDisabledColor};
      & > .svg-bg  {
        fill: ${btnDisabledBg};
      }
    }
  `
      : ''}

  &.loading > .svg-bg {
    fill: ${btnDisabledBg};
  }
`

const btnSm = css`
  font-size: ${bh / 1.5}px;
  padding: ${bh / 2 - 1}px ${bh - 1}px;
`

const btnMd = css`
  font-size: ${bh / 1.2}px;
  padding: ${bh / 1.6 - 1}px ${bh - 1}px;
`

const btnXl = css`
  font-size: ${bh * 1.5}px;
  height: ${bh * 4}px;
  line-height: ${bh * 4 - 2}px;
  padding: 0 ${bh * 2 - 1}px;
`

const btnIcon = css`
  padding-left: ${bh}px;
  padding-right: ${bh}px;
`

const btnLink = css`
  background: transparent;
  color: ${cmsContentColor};

  ${(p: any) =>
    p.primary
      ? `
    color: ${btnPrimaryBg};
  `
      : ''}
  ${(p: any) =>
    p.secondary
      ? `
    color: ${btnSecondaryBg};
  `
      : ''}
  ${(p: any) =>
    p.danger
      ? `
    color: ${btnDangerBg};
  `
      : ''}
`

export const btnBlock = css`
  width: 100%;
  display: block;
`

const StyledButton = styled.button`
  ${btn}

  ${(props: any) => (props.sm ? btnSm : '')}
  ${(props: any) => (props.md ? btnMd : '')}
  ${(props: any) => (props.xl ? btnXl : '')}
  ${(props: any) => (props.primary ? btnPrimary : '')}
  ${(props: any) => (props.secondary ? btnSecondary : '')}
  ${(props: any) => (props.danger ? btnDanger : '')}
  ${(props: any) => (props.svg ? btnSvg : '')}
  ${(props: any) => (props.block ? btnBlock : '')}
  ${(props: any) => (props.link ? btnLink : '')}
  ${(props: any) => (props.icon ? btnIcon : '')}

  ${btnDisabled}
`

const StyledLink = StyledButton.withComponent(Link)
const StyledA = StyledButton.withComponent('a')

function renderChildren(children: any) {
  if (children && children.map) {
    return children.map((child: any, key: number) =>
      typeof child === 'string' ? <span key={key}>{child}</span> : child,
    )
  }

  return children
}

/**
 * @typedef Button
 * @prop {any} [children]
 * @prop {boolean} [loading]
 * @prop {boolean} [disabled]
 * @prop {boolean} [primary]
 * @prop {boolean} [primary]
 * @prop {boolean} [sm]
 * @prop {boolean} [lg]
 * @prop {boolean} [svg]
 * @prop {boolean} [secondary]
 * @prop {any} [onClick]
 * @prop {string} [className]
 * @prop {string} [to]
 * @prop {string} [href]
 * @prop {string} [link]
 */

/**
 * @param {Button} props
 */
function Button({ children, loading, className, to, href, ...rest }: any) {
  const url = to || href
  const linkProp = getUrlProp(url)
  const Component: FunctionComponent = getComponent(url)

  return (
    <Component
      className={loading ? `loading ${className}` : className}
      {...rest}
      {...linkProp}
    >
      {loading ? (
        <SmallCenteredLoader
          xl={rest.xl}
          style={{ zIndex: 2 }}
        />
      ) : null}

      <span style={loading ? { opacity: 0 } : null}>{renderChildren(children)}</span>

      {rest.svg ? (
        <BtnBg
          className="svg-bg"
          width={rest.xl ? btnBgWidth * btnBgXlMulti : btnBgWidth}
          height={rest.xl ? btnBgHeight * btnBgXlMulti : btnBgHeight}
        />
      ) : null}
    </Component>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  danger: PropTypes.bool,
  block: PropTypes.bool,
  primary: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  svg: PropTypes.bool,
  xl: PropTypes.bool,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  link: PropTypes.bool,
}
Button.defaultProps = {
  className: '',
}

function getComponent(url: string) {
  if (!url) {
    return StyledButton
  }

  if (isSelfUrl(url) && !isRedirectNeeded(url)) {
    return StyledLink
  }

  return StyledA
}

function getUrlProp(url: string) {
  if (!url) {
    return {}
  }

  if (isSelfUrl(url) && !isRedirectNeeded(url)) {
    return { to: url, exact: true }
  }

  return { href: url }
}

export default Button
