import { getGames } from '../../../containers/GamesBlock/service'
import { GamesWithCategories } from '../../../containers/GamesBlock/types'
import { gamesWithCategoriesSchema } from '../../../containers/GamesBlock/validator'
import { useQueryWithValidator } from '../../useQueryWithValidator'
import { GAMES_CACHE_TIME } from './constants'
import { getGameCacheKey } from './utils'

interface Props<TSelectData> {
  select?: (data: GamesWithCategories) => TSelectData
}

export function useGamesWithCategories<TSelectData = GamesWithCategories>({ select }: Props<TSelectData> = {}) {
  return useQueryWithValidator({
    cacheKey: getGameCacheKey(),
    queryFn: getGames,
    schema: gamesWithCategoriesSchema,
    options: {
      gcTime: GAMES_CACHE_TIME,
      staleTime: GAMES_CACHE_TIME,
      select,
    },
  })
}
