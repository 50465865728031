import { Link } from 'react-router-dom'

import styled from 'styled-components'
import { mainHeaderColor } from 'utils/style-utils'

export const ProfileLink = styled(Link)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0 1rem;

  font-size: 0.875rem;
  color: ${mainHeaderColor};
  font-weight: 800;

  > svg {
    width: 1.6rem;
    height: 1.6rem;
    fill: ${mainHeaderColor};
  }
`
