/* eslint-disable no-multi-spaces */
import { darken, lighten } from '../utils/style-fns'
import baseTheme from './baseTheme'
import bannerImg from './img/banner-collect.jpg'
import logo from './img/collect.svg'
import logoSymbol from './img/collect_symbol.svg'
import footerLogo from './img/collect_white.svg'

export const collectLogo = logoSymbol
const logoHeight = '75%'
const bannerBigImg = bannerImg

const extraFontFamily = 'Open Sans'
const baseFontFamily = 'Open Sans, Verdana, Geneva, sans-serif'

/* eslint-disable prefer-destructuring */
const white = '#ffffff'
const grayLightest = '#dddddd'
const grayLighter = '#aaaaaa'
const grayLight = '#888888'
const gray = '#666666'
const grayDark = '#35343b'
const grayDarker = '#26252b'
const grayDarkest = '#1f1e24'
const black = '#000'
const color = grayDarker

const bg = grayDarkest

const brandPrimary = '#4b82da'
const brandPrimaryDark = '#345995'
const brandPrimaryDarker = '#284371'
const brandSecondary = '#F05D5E'
const brandSecondaryDark = '#a40e11'
const dangerBg = baseTheme.dangerBg

const mainHeaderBg = '#fff'
const mainHeaderColor = brandPrimaryDark
const secondaryHeaderBg = brandPrimaryDark
const secondaryHeaderColor = '#fff'

const cmsContentBg = white
const cmsContentColor = grayDark
const cmsContentSidebarBg = grayDark
const cmsContentSidebarActiveBg = white
const cmsContentSidebarActiveColor = brandPrimary
const cmsContentSidebar3DeepBg = grayDarkest
const cmsContentSidebar3DeepColor = grayDark
const cmsContentBorder = grayDarker
const hrColor = 'rgba(0, 0, 0, 0.1)'

/** FORM */
const formFieldBg = darken(white, 0.1)
const formFieldColor = grayDarkest
const formFieldLabelColor = grayDarker
const formFieldBorderColor = darken(formFieldBg, 0.2)
const formFieldPlaceholderColor = grayDark

const formFieldDisableBg = darken(formFieldBg, 0.1)
const formFieldDisableColor = darken(formFieldDisableBg, 0.3)
const formFieldDisableBorderColor = formFieldDisableBg

const formFieldOutline = brandPrimary
const formFieldError = brandSecondary
const formFieldErrorColor = brandSecondaryDark

const formCheckboxBg = grayLight
const formCheckboxColor = formFieldColor
const formCheckboxCheckedBg = brandPrimaryDark
const formCheckboxCheckedColor = formFieldColor
const formFieldAutocompleteColor = grayDark

/** BOX */
const boxHeaderBg = brandPrimaryDark
const boxHeaderColor = white
const boxSubHeaderBg = grayLighter
const boxSubHeaderColor = grayDarkest
const boxContentBg = grayLightest
const boxContentColor = grayDarker
const boxFooterBg = grayLighter
const boxFooterColor = grayDarkest

/** Table */
const tableHeaderBg = grayLighter
const tableHeaderColor = grayDarkest
const tableBg = grayLightest
const tableColor = boxContentColor
const tableDangerBg = lighten(dangerBg, 0.85)

/** BTN */
const btnDefaultBg = grayLighter
const btnDefaultColor = grayDarkest
const btnDefaultHoverBg = grayLight
const btnDefaultHoverColor = black

const btnPrimaryBg = brandPrimaryDark
const btnPrimaryColor = white
const btnPrimaryHoverBg = darken(brandPrimaryDark, 0.4)
const btnPrimaryHoverColor = white

const btnSecondaryBg = brandPrimary
const btnSecondaryColor = white
const btnSecondaryHoverBg = darken(brandPrimary, 0.2)
const btnSecondaryHoverColor = white

const btnDangerBg = brandSecondary
const btnDangerColor = white
const btnDangerHoverBg = brandSecondaryDark
const btnDangerHoverColor = white

const btnDisabledBg = gray
const btnDisabledColor = grayLighter

/** SELECT BOX */
const selectBoxBg = boxContentBg
const selectBoxColor = boxContentColor
const selectBoxIconColor = boxHeaderBg
const selectBoxActiveBg = boxHeaderBg
const selectBoxActiveColor = white
const selectBoxActiveIconColor = white
const selectBoxSelectedBg = boxContentBg
const selectBoxSelectedColor = boxContentColor
const selectBoxSelectedIconColor = boxHeaderBg
const selectBoxSelectedBorderColor = boxHeaderBg
const selectBoxWarningBg = baseTheme.warningBg
const selectBoxWarningColor = brandSecondaryDark
const selectBoxWarningIconColor = brandSecondaryDark

const alertBoxShadow = `rgba(0,0,0,0.1) 0 ${baseTheme.bh / 4}px ${baseTheme.bh / 2}px`
const loaderColor = brandPrimary

export default {
  ...baseTheme,
  alertBoxShadow,
  tableHeaderBg,
  tableHeaderColor,
  tableBg,
  tableColor,
  tableDangerBg,

  extraFontFamily,
  baseFontFamily,
  bg,
  color,
  logo,
  logoHeight,
  footerLogo,
  bannerImg,
  bannerBigImg,

  dangerBg,
  brandPrimary,
  brandPrimaryDark,
  brandPrimaryDarker,
  brandSecondary,
  brandSecondaryDark,
  white,
  grayLightest,
  grayLighter,
  grayLight,
  gray,
  grayDark,
  grayDarker,
  grayDarkest,
  black,
  hrColor,

  mainHeaderBg,
  mainHeaderColor,
  secondaryHeaderBg,
  secondaryHeaderColor,

  cmsContentBg,
  cmsContentColor,
  cmsContentSidebarBg,
  cmsContentSidebarActiveBg,
  cmsContentSidebarActiveColor,
  cmsContentSidebar3DeepBg,
  cmsContentSidebar3DeepColor,
  cmsContentBorder,

  boxHeaderBg,
  boxHeaderColor,
  boxSubHeaderBg,
  boxSubHeaderColor,
  boxContentBg,
  boxContentColor,
  boxFooterBg,
  boxFooterColor,

  formFieldBg,
  formFieldColor,
  formFieldLabelColor,
  formFieldBorderColor,
  formFieldPlaceholderColor,
  formFieldDisableBg,
  formFieldDisableColor,
  formFieldDisableBorderColor,
  formFieldOutline,
  formFieldError,
  formFieldErrorColor,
  formCheckboxBg,
  formCheckboxColor,
  formCheckboxCheckedBg,
  formCheckboxCheckedColor,
  formFieldAutocompleteColor,

  btnDefaultBg,
  btnDefaultColor,
  btnDefaultHoverBg,
  btnDefaultHoverColor,
  btnPrimaryBg,
  btnPrimaryColor,
  btnPrimaryHoverBg,
  btnPrimaryHoverColor,
  btnSecondaryBg,
  btnSecondaryColor,
  btnSecondaryHoverBg,
  btnSecondaryHoverColor,
  btnDangerBg,
  btnDangerColor,
  btnDangerHoverBg,
  btnDangerHoverColor,
  btnDisabledBg,
  btnDisabledColor,

  selectBoxBg,
  selectBoxColor,
  selectBoxIconColor,
  selectBoxActiveBg,
  selectBoxActiveColor,
  selectBoxActiveIconColor,
  selectBoxSelectedBg,
  selectBoxSelectedColor,
  selectBoxSelectedIconColor,
  selectBoxSelectedBorderColor,
  selectBoxWarningBg,
  selectBoxWarningColor,
  selectBoxWarningIconColor,

  loaderColor,
}
