import React from 'react'

import { Icon } from 'components/IconLink/Icon'
import { IconKey } from 'components/IconLink/iconMap'

import { StyledUrlLink } from './styles'

interface IconLinkProps {
  label: string
  icon: IconKey
  url?: string
  link?: string
  isEnabled?: boolean
}

export function IconLink({ label, icon, url, link, isEnabled, ...rest }: IconLinkProps) {
  return (
    <StyledUrlLink
      url={url}
      link={link}
      {...rest}
    >
      <Icon icon={icon} />
      <span>{label}</span>
    </StyledUrlLink>
  )
}
