import React from 'react'
import { createRoot } from 'react-dom/client'

import { isTouchCached } from 'utils/device'

import Providers from './Providers'
import App from './containers/App/index'
import ErrorBoundary from './containers/ErrorBoundary'

const rootElement = document.getElementById('app')
rootElement.classList.add(isTouchCached() ? 'touch' : 'no-touch')
const root = createRoot(rootElement)

export function render() {
  hideLoader()

  root.render(
    <ErrorBoundary>
      <Providers>
        <App />
      </Providers>
    </ErrorBoundary>,
  )
}

function hideLoader() {
  const loader = document.getElementById('loader')
  document.body.removeChild(loader)
}
