/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import Autosuggest, { ChangeEvent, SuggestionSelectedEventData } from 'react-autosuggest'

import { FormProps } from '../types'
import { SuggestionComponent } from './SuggestionComponent'
import { StyledWrapper } from './styles/StyledWrapper'
import { Suggestion } from './types'
import { getFetchSuggestions, getSuggestionValue } from './utils'

interface Props<TData, TParams> extends FormProps {
  fetchData: (value: string, queryParams?: TParams) => Promise<Suggestion<TData>[]>
  queryParams?: TParams
  onChange: (value: Suggestion<TData>) => void
}

export function Autocomplete<TData, TParams>({
  id,
  disable,
  value,
  fetchData,
  queryParams,
  onChange,
}: Props<TData, TParams>) {
  const [transformedSuggestions, setTransformedSuggestions] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    if (!!currentValue && !value) {
      setInputValue('')
    }
    setCurrentValue(value)
  }, [value])

  const debouncedOnSuggestionFetch = useCallback(
    getFetchSuggestions<TData, TParams>({ fetchData, setTransformedSuggestions, queryParams }),
    [queryParams],
  )
  const onInputValueChange = (_: FormEvent<HTMLElement>, { newValue }: ChangeEvent) => setInputValue(newValue)
  const onSuggestionsClearRequested = () => setTransformedSuggestions([])

  return (
    <StyledWrapper>
      <Autosuggest
        id={id}
        suggestions={transformedSuggestions}
        onSuggestionsFetchRequested={debouncedOnSuggestionFetch}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={SuggestionComponent}
        onSuggestionSelected={(_, payload: SuggestionSelectedEventData<Suggestion<TData>>) =>
          onChange(payload.suggestion)
        }
        inputProps={{
          onChange: onInputValueChange,
          value: inputValue,
          disabled: disable,
        }}
      />
    </StyledWrapper>
  )
}
