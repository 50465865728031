import { Link } from 'react-router-dom'

import styled from 'styled-components'
import { transition, white } from 'utils/style-utils'

export const LogoBox = styled.div`
  height: 100%;
`

export const LogoLink = styled(Link)`
  height: 100%;
  padding: 0 0.75rem;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    img {
      filter: brightness(150%) drop-shadow(0 0 16px ${white});
    }
  }
`

export const Img = styled.img`
  height: 2rem;
  display: block;

  filter: brightness(100%);
  ${transition('filter', 0.6)};
`
