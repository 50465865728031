import React, { ErrorInfo } from 'react'

import Translator from 'bazinga-translator'

import BetorLogo from '../Collect/PartnerInfo/BetorLogo'
import { ButtonWrapper } from './styles/ButtonWrapper'
import { Label } from './styles/Label'
import { Name } from './styles/Name'
import { Pre } from './styles/Pre'
import { StyledDate } from './styles/StyledDate'
import { Wrapper } from './styles/Wrapper'

interface Props {
  error: Error
  errorInfo: ErrorInfo
}

function ErrorFallback({ error, errorInfo }: Props) {
  return (
    <Wrapper>
      <BetorLogo />
      <Label>{Translator.trans('unknown_error', {}, 'errors')}</Label>
      <StyledDate>{new Date().toLocaleString()}</StyledDate>
      <ButtonWrapper>
        <a href="/">{Translator.trans('Back to casino', {}, 'messages')}</a>
      </ButtonWrapper>
      <Name>
        {error.name}: {error?.message}
      </Name>
      <Pre>{error?.stack}</Pre>
      <Pre>{errorInfo?.componentStack}</Pre>
    </Wrapper>
  )
}

export default ErrorFallback
